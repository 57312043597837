import styled from 'styled-components';
import { HashLink as HashAnchor } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Div } from 'styles/elements/div';

const LegalContent = styled(Div)`
  
`;

const Text = styled.p`
  font-style: normal;
  font-family: 'Open Sans-Regular', Helvetica, Arial, serif;
  font-size: 19px;
  line-height: 1.5;
`;

const List = styled.ol`
  font-style: normal;
  font-family: 'Open Sans-Regular', Helvetica, Arial, serif;
  font-size: 19px;
  line-height: 1.5;
  list-style-type: decimal;
  counter-reset: list-item item;

  li {
    counter-increment: item;
    list-style-type: decimal;
    padding: 10px 0 10px 0;
  }

  li ol li {
    list-style-type: none;
    padding: 10px 0 0;
  }

  li ol li:before {
    content: counters(item, ".") " ";
    padding-right: 2px;
  }
`;

const AlphabetisedList = styled(List)`
  list-style-type: lower-alpha;
`;

const PrivacyPolicyOrderedList = styled.ol`
  list-style-type: lower-alpha;
  font-style: normal;
  font-family: 'Open Sans-Regular', Helvetica, Arial, serif;
  font-size: 19px;
`;

const UnorderedList = styled.ul`
  font-style: normal;
  font-family: 'Open Sans-Regular', Helvetica, Arial, serif;
  font-size: 19px;
  line-height: 1.5;
`;

const Header3 = styled.h3`
  font-size: 1.3em;
`;

const HashLink = styled(HashAnchor)`
  color: black; 
  text-decoration: underline; 
  text-underline-position: under;
  :visited {
    color: white;
  }
  :focus {
    outline-color: black;
  }
`;

const LinkTo = styled(Link)`
  color: black; 
  text-decoration: underline; 
  text-underline-position: under;
  :visited {
    color: white;
  }
  :focus {
    outline-color: black;
  }
`;

const ExternalLink = styled.a`
  color: black; 
  text-decoration: underline; 
  text-underline-position: under;
  :visited {
    color: white;
  }
  :focus {
    outline-color: black;
  }
`;

const TableWrapper = styled(Div)`
    overflow: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    font-size; 80%;
`;

const TableHeader = styled.th`
    vertical-align: bottom;
    border: 1px solid black;
    padding: 5px;
    :first-of-type {
        min-width: 150px;
    }
`;

const TableCell = styled.td`
    vertical-align: top;
    border: 1px solid black;
    padding: 5px;
`;

LegalContent.Text = Text;
LegalContent.List = List;
LegalContent.AlphabetisedList = AlphabetisedList;
LegalContent.PrivacyPolicyOrderedList = PrivacyPolicyOrderedList;
LegalContent.UnorderedList = UnorderedList;
LegalContent.ListItem = styled.li``;
LegalContent.Header1 = styled.h1``;
LegalContent.Header2 = styled.h2``;
LegalContent.Header3 = Header3;
LegalContent.Header4 = styled.h4``;
LegalContent.TableHeader = TableHeader;
LegalContent.TableRow = styled.tr``;
LegalContent.TableCell = TableCell;
LegalContent.TableWrapper = TableWrapper;
LegalContent.TableBody = styled.tbody``;
LegalContent.Table = Table;
LegalContent.LinkTo = LinkTo;
LegalContent.HashLink = HashLink;
LegalContent.Link = ExternalLink;

export default LegalContent;
