import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body, #root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right:0;
    overflow: hidden;
  }
  
  body {
    background: ${props => props.theme.bgPrimaryLight};
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ${props => props.theme.fontPrimary};
    
   background-color: #009cdc;
  }
  
  #root {
    display: flex;
  }
  
  button {
    :hover {
      cursor: pointer;
    }
  }
`;
