import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import freeze from 'redux-freeze';
import _ from 'lodash';
import rootReducer from 'redux/rootReducer';

const environment = process.env.REACT_APP_ENVIRONMENT;

export default () => {

  if (environment === 'production') {
    const middlewares = _.compact([thunk, freeze]);
    const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
    return createStoreWithMiddleware(rootReducer);
  }

  const logger = createLogger();
  const middlewares = _.compact([thunk, freeze, logger]);
  const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
  return createStoreWithMiddleware(rootReducer);
};
