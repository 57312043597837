import styled from 'styled-components';
import media from 'styles/media';

export const H1 = styled.h1`
  font-family: 'Quicksand', Helvetica, Arial, sans-serif;
  font-size: 28px;
  color: white;
  ${media.extraExtraSmallAndAbove`
    font-size: 40px;
  `}
`;
