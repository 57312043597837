import React from "react";
import LegalContent from "styles/blocks/legalContent";

export function TermsOfUse() {
  return (
    <LegalContent>
      <LegalContent.Text>This page (together with our <LegalContent.LinkTo to="/legal/disclaimer">disclaimer</LegalContent.LinkTo>,
        and <LegalContent.LinkTo
          to="/legal/privacy"
        >privacy policy
        </LegalContent.LinkTo>) tells you the terms on which
        you may make use of our website patients.econsult.health (“Website”), whether as a guest or a registered
        user.
        Please read these terms of use carefully before you start to use the Website. By using the Website, you
        indicate
        that you accept these terms of use and that you agree to abide by them. If you do not agree to these
        terms of use,
        please refrain from using the Website.
      </LegalContent.Text>
      <br/>
      <LegalContent.Header2>INFORMATION ABOUT US</LegalContent.Header2>
      <LegalContent.Text>The Website is operated by eConsult Health Limited, registered in England and Wales with registered
        number 7628675
        and whose registered office is at Nightingale House, 46-48 East Street, Epsom, Surrey KT17 1HQ (“we” or
        “us”).
      </LegalContent.Text>
      <br/>
      <LegalContent.Header2>ACCESSING THE WEBSITE</LegalContent.Header2>
      <LegalContent.Text>Access to the Website is permitted on a temporary basis, and we reserve the right to withdraw or amend
        the materials
        we provide on the Website without notice (see below). We will not be liable if for any reason the
        Website is unavailable
        at any time or for any period.
      </LegalContent.Text><br/>
      <LegalContent.Text>Use of our online consultations is only permitted within the UK.</LegalContent.Text>
      <LegalContent.Text>You are responsible for making all arrangements necessary for you to have access to our site.</LegalContent.Text>
      <LegalContent.Text>From time to time, we may restrict access to some parts of the Website.</LegalContent.Text>
      <LegalContent.Text> If you choose, or you are provided with, a
        user identification code, password or any other piece of information as part of our security procedures,
        you must treat
        such information as confidential, and you must not disclose it to any third party. We have the right to
        disable any
        user identification code or password, whether chosen by you or allocated by us, at any time, if in our
        opinion you
        have failed to comply with any of the provisions of these terms of use.
      </LegalContent.Text>
      <LegalContent.Text>You are responsible for making all arrangements
        necessary for you to have access to the Website. You are also responsible for ensuring that all persons
        who access the
        Website through your internet connection are aware of these terms, and that they comply with them.
      </LegalContent.Text>

      <LegalContent.Header2>PROHIBITED USES</LegalContent.Header2>
      <LegalContent.Text>You may use the Website only for lawful purposes. You may not use the Website:</LegalContent.Text>
      <LegalContent.UnorderedList>
        <LegalContent.ListItem>
          In any way that breaches any applicable local, national or international law or regulation.
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          For the purpose of harming or attempting to harm minors in any way.
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          To send, knowingly receive, upload, download, use or re-use any material which does not comply with
          our Content
          Standards set out below.
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional
          material or
          any other form of similar solicitation (spam).
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          To knowingly transmit any data, send or upload any material that contains viruses, trojan horses,
          worms, time-bombs,
          keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed
          to adversely
          affect the operation of any computer software or hardware.
        </LegalContent.ListItem>
      </LegalContent.UnorderedList>
      <LegalContent.Text>
      You also agree:
      </LegalContent.Text>
      <LegalContent.UnorderedList>
        <LegalContent.ListItem>
          Not to reproduce, duplicate, copy or re-sell any part of the Website in contravention with these
          terms of website use.
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          Not to access without authority, interfere with, damage or disrupt: any part of the Website; any
          equipment or
          network on which the Website is stored; any software used in the provision of the Website; or any
          equipment or
          network or software owned or used by any third party.
        </LegalContent.ListItem>
      </LegalContent.UnorderedList>

      <LegalContent.Header2>INTERACTIVE SERVICES</LegalContent.Header2>
      <LegalContent.Text>We may from time to time provide interactive services on the Website, including, without limitation: chat
        rooms;
        bulletin boards; videos; video consultations; and online communities (each an “Interactive
        Service”).
      </LegalContent.Text>
      <LegalContent.Text> Where we do provide
        any Interactive Service, we will provide clear information to you about the kind of service offered, if
        it is moderated
        and what form of moderation is used (including whether it is human or technical).
      </LegalContent.Text>
      <LegalContent.Text>We will do our best to assess any
        possible risks for users (and in particular, for children) from third parties when they use any
        interactive service
        provided on the Website, and we will decide in each case whether it is appropriate to use moderation of
        the relevant
        service (including what kind of moderation to use) in the light of those risks. However, we are under no
        obligation to
        oversee, monitor or moderate any Interactive Service we provide on the Website, and we expressly exclude
        our liability
        for any loss or damage arising from the use of any Interactive Service by a user in contravention of our
        Content Standards
        set out below, whether the service is moderated or not.
      </LegalContent.Text>
      <LegalContent.Text> The use of any of our Interactive Services by a minor is subject to
        the consent of their parent or guardian. We advise parents who permit their children to use an
        Interactive Service that it is
        important that they communicate with their children about their safety online, as moderation is not
        foolproof. Minors who are
        using any interactive service should be made aware of the potential risks to them.
      </LegalContent.Text>
      <LegalContent.Text>Where we do moderate an Interactive Service,
        we will normally provide you with a means of contacting the moderator, should a concern or difficulty
        arise.
      </LegalContent.Text>

      <LegalContent.Header2>CONTENT STANDARDS</LegalContent.Header2>
      <LegalContent.Text>These Content Standards apply to any and all material which you contribute to the Website
        (“Contributions”), and to
        any Interactive Services associated with it.
      </LegalContent.Text>
      <LegalContent.Text>You must comply with the spirit of the following standards as well as the
        letter. The standards apply to each part of any Contribution as well as to its whole.
      </LegalContent.Text>
      <LegalContent.Text>Contributions must be: accurate
        (where they state facts); be genuinely held (where they state opinions); and comply with applicable law
        in the UK and
        in any country from which they are posted.
      </LegalContent.Text>
      <LegalContent.Text>Contributions must not: contain any material which is defamatory of any person;
        contain any material which is obscene, offensive, hateful or inflammatory; promote sexually explicit
        material; promote
        violence; promote discrimination based on race, sex, religion, nationality, disability, sexual
        orientation or age;
        infringe any copyright, database right or trade mark of any other person; be likely to deceive any
        person; be made in
        breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;
        promote any illegal
        activity; be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or
        needless anxiety; be
        likely to harass, upset, embarrass, alarm or annoy any other person; be used to impersonate any person,
        or to misrepresent
        your identity or affiliation with any person; give the impression that they emanate from us, if this is
        not the case; advocate,
        promote or assist any unlawful act such as (by way of example only) copyright infringement or computer
        misuse; or misguide
        any users with incorrect clinical information.
      </LegalContent.Text>

      <LegalContent.Header2>SUSPENSION AND TERMINATION</LegalContent.Header2>
      <LegalContent.Text>We will determine, in our discretion, whether there has been a breach of these terms of use through your
        use of the
        Website. When a breach has occurred, we may take such action as we deem appropriate.
      </LegalContent.Text>

      <LegalContent.Text>Failure to comply with these terms
        of use constitutes a material breach of these terms of use upon which you are permitted to use the
        Website, and may result
        in our taking all or any of the following actions: immediate, temporary or permanent withdrawal of your
        right to use the
        Website; immediate, temporary or permanent removal of any posting or material uploaded by you to the
        Website; issue of a
        warning to you; legal proceedings against you for reimbursement of all costs on an indemnity basis
        (including, but not
        limited to, reasonable administrative and legal costs) resulting from the breach; further legal action
        against you; and/or
        disclosure of such information to law enforcement authorities as we reasonably feel is necessary.
      </LegalContent.Text>

      <LegalContent.Text> We exclude liability for
        actions taken in response to breaches of these terms of use. The responses described above are not
        exhaustive, and we may
        take any other action we reasonably deem appropriate.
      </LegalContent.Text>

      <LegalContent.Header2>INTELLECTUAL PROPERTY RIGHTS</LegalContent.Header2>
      <LegalContent.Text>We are the owner or the licensee of all intellectual property rights in the Website, and in the material
        published on it.
        Those works are protected by copyright laws and treaties around the world. All such rights are
        reserved.
      </LegalContent.Text>
      <LegalContent.Text> You may print off one copy, and may download extracts, of any page(s) from the Website for your personal
        reference
        and you may draw the attention of others within your organisation to material posted on the Website.
      </LegalContent.Text>
      <LegalContent.Text> You must not modify the paper or digital copies of any
        materials you have printed off or downloaded in any way, and you must not use any illustrations,
        photographs, video or audio
        sequences or any graphics separately from any accompanying text.
      </LegalContent.Text>
      <LegalContent.Text>Our status (and that of any identified contributors) as the
        authors of material on the Website must always be acknowledged. You must not use any part of the
        materials on the Website for
        commercial purposes without obtaining a licence to do so from us or our licensors.
      </LegalContent.Text>
      <LegalContent.Text> If you print off, copy or download any part
        of the Website in breach of these terms of use, your right to use the Website will cease immediately and
        you must, at our option,
        return or destroy any copies of the materials you have made.
      </LegalContent.Text>

      <LegalContent.Header2>RELIANCE ON INFORMATION POSTED</LegalContent.Header2>
      <LegalContent.Text>The content on the Website is provided for general information only. It is not intended to amount to
        advice on which
        you should rely. You must consult your GP or other medical adviser to obtain professional or specialist
        advice before taking,
        or refraining from, any action on the basis of the content on the Website.
      </LegalContent.Text>

      <LegalContent.Header2>THE WEBSITE CHANGES REGULARLY</LegalContent.Header2>
      <LegalContent.Text>We may update the Website from time to time, and may change the content at any time. If the need arises,
        we may suspend
        access to the Website, or close it indefinitely. Any of the material on the Website may be out of date
        at any given time,
        and we are under no obligation to update such material. We do not guarantee that the Website, or any
        content on it, will
        be free from errors or omissions.
      </LegalContent.Text>

      <LegalContent.Header2>OUR LIABILITY</LegalContent.Header2>
      <LegalContent.Text>Please refer to our <LegalContent.LinkTo to="/legal/disclaimer">disclaimer</LegalContent.LinkTo>.</LegalContent.Text>
      <LegalContent.Text> Information about you and your visits to the Website: we process information about you
        in accordance with our <LegalContent.LinkTo to="/legal/privacy">privacy policy</LegalContent.LinkTo>.
        By using the Website, you consent to such processing and you warrant that all data
        provided by you is accurate.
      </LegalContent.Text>

      <LegalContent.Header2>UPLOADING MATERIAL TO THE WEBSITE</LegalContent.Header2>
      <LegalContent.Text>Whenever you make use of a feature that allows you to upload material to the Website, or to make contact
        with other
        users of the Website, you must comply with the Content Standards set out above. You warrant that any
        such Contribution
        does comply with those standards, and you indemnify us for any breach of that warranty.
      </LegalContent.Text>
      <LegalContent.Text>Any material you upload to the
        Website will be considered non-confidential and non- proprietary, and we have the right to use, copy,
        distribute and
        disclose to third parties any such material for any purpose. We also have the right to disclose your
        identity to any
        third party who is claiming that any material posted or uploaded by you to the Website constitutes a
        violation of their
        intellectual property rights, or of their right to privacy.
      </LegalContent.Text>
      <LegalContent.Text> We will not be responsible, or liable to any third party,
        for the content or accuracy of any materials posted by you or any other user of the Website.
      </LegalContent.Text>
      <LegalContent.Text>We have the right to remove any material or posting you make on the Website if, in our opinion, such
        material does
        not comply with the Content Standards set out above.
      </LegalContent.Text>

      <LegalContent.Header2>VIRUSES, HACKING AND OTHER OFFENCES</LegalContent.Header2>
      <LegalContent.Text>We do not guarantee that the Website will be secure or free from bugs or viruses.</LegalContent.Text>
      <LegalContent.Text>You are responsible for configuring your information technology, computer programmes and platform in
        order to access
        the Website. You should use your own virus protection software.
      </LegalContent.Text>
      <LegalContent.Text>You must not misuse the Website by knowingly introducing viruses, trojans, worms, logic bombs or other
        material
        which is malicious or technologically harmful. You must not attempt to gain unauthorised access to the
        Website, the
        server on which the Website is stored or any server, computer or database connected to the Website. You
        must not
        attack the Website via a denial-of-service attack or a distributed denial-of service attack.
      </LegalContent.Text>
      <LegalContent.Text> By breaching this provision,
        you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach
        to the relevant
        law enforcement authorities and we will co-operate with those authorities by disclosing your identity to
        them. In the
        event of such a breach, your right to use the Website will cease immediately.
      </LegalContent.Text>
      <LegalContent.Text>
        Please refer to our <LegalContent.LinkTo to="/legal/disclaimer">disclaimer</LegalContent.LinkTo> for the limitations of our liability.
      </LegalContent.Text>

      <LegalContent.Header2>LINKING TO THE WEBSITE</LegalContent.Header2>
      <LegalContent.Text>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our
        reputation
        or take advantage of it, but you must not establish a link in such a way as to suggest any form of
        association, approval
        or endorsement on our part where none exists.
      </LegalContent.Text>
      <LegalContent.Text>You must not establish a link from any website that is not owned by you.</LegalContent.Text>

      <LegalContent.Text>The Website must not be framed on any other site, nor may you create a link to any part of the Website
        other than the
        home page. We reserve the right to withdraw linking permission without notice. The website from which
        you are linking
        must comply in all respects with the Content Standards set out above.
      </LegalContent.Text>
      <LegalContent.Text>If you wish to make any use of material on the
        Website other than that set out above, please address your request to the local surgery.
      </LegalContent.Text>

      <LegalContent.Header2>LINKS FROM THE WEBSITE</LegalContent.Header2>
      <LegalContent.Text>Where the Website contains links to other sites and resources provided by third parties, these links are
        provided
        for your information only. We have no control over the contents of those sites or resources, and accept
        no responsibility
        for them or for any loss or damage that may arise from your use of them.
      </LegalContent.Text>
      <LegalContent.Text>Please refer to our <LegalContent.LinkTo to="/legal/disclaimer">disclaimer</LegalContent.LinkTo> for the limitations of our
        liability.
      </LegalContent.Text>

      <LegalContent.Header2>JURISDICTION AND APPLICABLE LAW</LegalContent.Header2>
      <LegalContent.Text>The English courts will have exclusive jurisdiction over any claim arising from, or related to, a visit
        to the Website
        although we retain the right to bring proceedings against you for breach of these conditions in your
        country of residence
        or any other relevant country.
      </LegalContent.Text>
      <LegalContent.Text>These terms of use and any dispute or claim arising out of or in connection with them or their
        subject matter or formation (including non-contractual disputes or claims) shall be governed by and
        construed in accordance
        with the law of England and Wales.
      </LegalContent.Text>

      <LegalContent.Header2>TRADE MARKS</LegalContent.Header2>
      <LegalContent.Text>“webGP” is a UK registered trade mark of eConsult Health Limited.</LegalContent.Text>

      <LegalContent.Header2>VARIATIONS</LegalContent.Header2>
      <LegalContent.Text>We may revise these terms of use at any time by amending this page. You are expected to check this page
        from time to
        time to take notice of any changes we made, as they are binding on you. Some of the provisions contained
        in these terms
        of use may also be superseded by provisions or notices published elsewhere on the Website.
      </LegalContent.Text>

      <LegalContent.Header2>YOUR CONCERNS</LegalContent.Header2>
      <LegalContent.Text>If you have any concerns about material which appears on the Website, please contact your GP or other
        medical adviser.
      </LegalContent.Text>
      <LegalContent.Text>Thank you for visiting the Website.</LegalContent.Text>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
    </LegalContent>
  );
}
