import React, { useEffect, useState, useRef } from "react";
import EConsultLogo from "assets/branding/logo_econsult_REVERSED_RGB.svg";
import Logo from "styles/blocks/logo";
import { Accessibility } from "components/legalNotices/accessibility";
import { Disclaimer } from "components/legalNotices/disclaimer";
import { H1 } from 'styles/elements/header';
import { PrivacyPolicy } from "components/legalNotices/privacypolicy";
import { TermsOfUse } from "components/legalNotices/termsofuse";
import LegalFooter from "styles/blocks/legalFooter";
import Home from "styles/blocks/home";
import { HiddenDiv } from "styles/elements/div";

function getLegalContent(id) {
  switch (id) {
    case "accessibility":
      return [<Accessibility/>, "Accessibility"];
    case "disclaimer":
      return [<Disclaimer/>, "Disclaimer"];
    case "privacy":
      return [<PrivacyPolicy/>, "eConsult Primary Care Privacy Notice"];
    case "terms":
      return [<TermsOfUse/>, "Terms of website use"];
    default:
      return null;
  }
}


export function LegalNotices({ match }) {
  const [content, heading] = getLegalContent(match.params.id);
  const [currentUrl, setCurrentUrl] = useState("");
  const homeRef = useRef(null);
  useEffect(() => {
    if (currentUrl !== match.url) {
      setCurrentUrl(match.url);
      homeRef.current.scrollTop = 0;
    }
  }, [match, currentUrl]);
  return (
    <Home ref={homeRef}>
      <nav name="Skip to content nav">
        <HiddenDiv>
          <a href="#mainContent">Skip to content</a>
        </HiddenDiv>
      </nav>
      <Home.ResponsiveContainer id="mainContent">
        <Home.HomeLink href="/">
          <Logo>
            <Logo.Image alt="eConsult logo" src={EConsultLogo}/>
          </Logo>
        </Home.HomeLink>
        <H1>{content ? heading : "We couldn't find that page"}</H1>
        {content}
      </Home.ResponsiveContainer>
      <LegalFooter>
        <LegalFooter.LinkList>
          <LegalFooter.ListItem><LegalFooter.LinkTo to="/legal/accessibility">Accessibility</LegalFooter.LinkTo></LegalFooter.ListItem>
          <LegalFooter.ListItem><LegalFooter.LinkTo to="/legal/disclaimer">Disclaimer</LegalFooter.LinkTo></LegalFooter.ListItem>
          <LegalFooter.ListItem><LegalFooter.LinkTo to="/legal/privacy">Privacy Policy</LegalFooter.LinkTo></LegalFooter.ListItem>
          <LegalFooter.ListItem><LegalFooter.LinkTo to="/legal/terms">Terms of Use</LegalFooter.LinkTo></LegalFooter.ListItem>
        </LegalFooter.LinkList>
      </LegalFooter>
    </Home>
  );
}
