/* eslint-disable no-shadow */

export const initialState = {
  practicesToDisplay: [],
  searchText: '',
  searchBarText: '',
  hasSearched: false,
  errorMessage: '',
  showFooterText: false
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'search/SHOW_HIDE_FOOTER_TEXT':
      return {
        ...state,
        showFooterText: action.payload
      };
    case 'search/GET_PRACTICES_SUCCESS':
      return {
        ...state,
        practicesToDisplay: action.payload,
        hasSearched: true,
        searchText: state.searchBarText
      };
    case 'search/UPDATE_SEARCH_TEXT':
      return {
        ...state,
        searchBarText: action.payload
      };
    case 'search/HIDE_RESULTS':
      return {
        ...state,
        searchBarText: '',
        hasSearched: false
      };
    case 'search/GET_PRACTICES_REQUEST':
      return {
        ...state
      };
    default:
      return {
        ...state,
        errorMessage: action.payload
      };
  }
};
