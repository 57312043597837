import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "styles/globalStyles";
import { STRINGS } from "config";
import { loadFonts } from "styles/fonts";
import { Route, Switch, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import theme from "./styles/theme";
import { Search } from "./components/search";
import { LegalNotices } from "./components/legalNotices";
import { BrowserNoticeBackground, BrowserNoticeContainer } from "./styles/blocks/browserNotice";

loadFonts();

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);
}

// TODO copying logic from webapp for consistently - but should consider using a library for browser detection when need grows
// - also consider moving IE9- checks from index.html to here.
const isIe10 = () => {
  return (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0)
    && navigator.userAgent.match('MSIE 10.0;');
};

function App() {
  useEffect(() => {
    ReactGA.initialize(STRINGS.GA_ID);
  }, []);
  usePageViews();
  if (isIe10()) {
    return (
      <>
        <BrowserNoticeBackground/>
        <BrowserNoticeContainer>
          <h1>Your browser is no longer supported. Unfortunately you won't be able to use this site.</h1>
          <h3>What can you do now?</h3>
          <p>You can access this site by <a href="https://www.getsafeonline.org/protecting-your-computer/update-your-browser" target="_blank" rel="noopener noreferrer">switching to any modern browser</a>.
          </p>
          <h3>Why isn't this browser supported?</h3>
          <p>Older versions of Internet Explorer are unable to provide the features and security needed to give you a good experience on this site.
          </p>
        </BrowserNoticeContainer>
      </>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle/>
        <Switch>
          <Route exact path="/legal/:id" component={LegalNotices}/>
          <Route exact path="/index.html" component={Search}/>
          <Route exact path="/" component={Search}/>
        </Switch>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default App;
