import React from 'react';
import { ReactComponent as SearchIconSvg } from './icon-search.svg';
import { ReactComponent as ClearIconSvg } from './icon-clear.svg';

export const SearchIcon = () => <SearchIconSvg style={{
  width: '27px',
  height: '27px'
}}/>;
export const ClearIcon = () => <ClearIconSvg style={{
  width: '27px',
  height: '27px'
}}/>;
