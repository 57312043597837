import _ from 'lodash';
import { STRINGS as CONSTANTS } from './constants';
import { developmentConfig } from './develop';
import { testConfig } from './test';
import { productionConfig } from './production';

const environment = process.env.REACT_APP_ENVIRONMENT;

const getEnvironment = () => {
  switch (environment) {
    case 'production':
      return productionConfig;
    case 'test':
      return testConfig;
    case 'development':
      return developmentConfig;
    default:
      return {};
  }
};

export const STRINGS = _.merge({}, CONSTANTS, getEnvironment());
