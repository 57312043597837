import styled from "styled-components";
import { Div } from "styles/elements/div";

const PracticeInfo = styled(Div)`
  `;


export const ResultHeader = styled.h2`
  font-family: 'Open Sans', Helvetica, Arial, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;    
  color: inherit;
  text-align: left;
  margin: 0 0 10px;
`;

const ResultBox = styled(Div)`
    background-color: white;
    padding: 30px;
    color: rgba(0, 100, 140, 1.0);
    box-sizing: border-box;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(245, 80, 99, 1.0);
    padding: 30px;
    margin-bottom: 30px;
`;

const GreyedResultBox = styled(Div)`
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    padding: 30px;
    margin-bottom: 30px;
`;

const Body = styled(Div)`
    font-family: 'OpenSans-Regular', Helvetica, Arial, serif;
    font-size: 19.0px;
    text-align: left;
    line-height: 1.8;
    color: black;
`;

const Link = styled.a`
    text-decoration: none;
    color: inherit;
    height: inherit;
    width: inherit;
`;

const BranchNames = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.2;
`;

const BranchDetails = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.2;
`;

const Highlight = styled.mark`
  background: #FFEB3B;
  padding: 3px 5px;
  margin-left: -5px;
`;

const ListItem = styled.li`
  line-height: inherit;
  padding-top: 15px;
`;

PracticeInfo.Link = Link;
PracticeInfo.Body = Body;
PracticeInfo.Text = styled.p``;
PracticeInfo.BranchesContainer = styled(Div)``;
PracticeInfo.ListItem = ListItem;
PracticeInfo.Highlight = Highlight;
PracticeInfo.GreyedResultBox = GreyedResultBox;
PracticeInfo.ResultBox = ResultBox;
PracticeInfo.ResultHeader = ResultHeader;
PracticeInfo.BranchNames = BranchNames;
PracticeInfo.BranchDetails = BranchDetails;

export default PracticeInfo;
