import React from 'react';
import { H1 } from 'styles/elements/header';
import HomeHeader from 'styles/blocks/homeHeader';
import DropletLogo from 'assets/branding/droplet-pair.svg';

export function SearchHeader() {
  return (
    <HomeHeader>
      <HomeHeader.Title>
        <H1>Get help fast from your own NHS GP practice</H1>
      </HomeHeader.Title>
    </HomeHeader>
  );
}
