import React, { useCallback } from 'react';
import { useMappedState } from 'redux-react-hook';
import { useSpring } from 'react-spring';
import HowItWorksFooter from 'styles/blocks/howItWorksFooter';


export function HowItWorks() {
  const mapState = useCallback(
    state => ({
      showFooterText: state.searchReducer.showFooterText,
    }), []
  );

  const { showFooterText } = useMappedState(mapState);

  const containerProps = useSpring({
    config: { friction: 60 },
    maxHeight: showFooterText ? '45vh' : '0vh',
    from: {
      maxHeight: showFooterText ? '0vh' : '0vh',
    }
  });

  return (
    <HowItWorksFooter aria-label="How it works">
      <HowItWorksFooter.TextContainer style={containerProps}>
        <HowItWorksFooter.Text>
          <p>eConsult is free to use for NHS patients. Use eConsult to ask your GP surgery about your health symptoms, conditions or treatment.
            You can even request things like sick notes and GP letters.</p>
          <HowItWorksFooter.ExampleUsages>
            <li>Find your symptom, condition or request.</li>
            <li>Fill out a quick form.</li>
            <li>The practice responds with advice, a prescription or an appointment.</li>
          </HowItWorksFooter.ExampleUsages>
        </HowItWorksFooter.Text>
      </HowItWorksFooter.TextContainer>
    </HowItWorksFooter>
  );
}
