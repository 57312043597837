import React from "react";
import styled from 'styled-components';

export const BrowserNoticeBackground = styled.div`
    position: absolute;
    background: rgb(0, 101, 173);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
`;

export const BrowserNoticeContainer = styled.div`
    position: absolute;
    background: white;
    top: 50px;
    width: 50%;
    padding: 60px;
    margin-left: 25%;
    box-sizing: border-box;
    z-index: 1001;
`;
