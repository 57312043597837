import styled from 'styled-components';
import { SearchIcon, ClearIcon } from 'assets/icons';
import { Div } from 'styles/elements/div';
import Input from 'styles/elements/input';

const InputWrapper = styled(Div)`
  position: relative;
  min-height: 45px;
`;
const SearchBar = styled(Div)`
  width: 100%;
  margin: auto;
  padding-bottom: 40px;
`;

const SearchInput = styled(Input)`
  width: 100%;
  height 100%;
  padding: 10px 50px 10px 10px;
  margin: 0;
  font-size: 19px;
  box-sizing: border-box;
`;

const SearchLabel = styled.label`
  display: block;
  padding-bottom: 28px;
  font-style: normal;
  font-family: 'Open Sans', Helvetica, Arial, serif;
  font-size: 19px;
  line-height: 1.5;
  color: black;
  background-color: #009cdc;
`;
const Buttons = styled.button`
  position: absolute;
  background: white;
  right: 3px;
  top: 10px;
  width: 50px;
  cursor: pointer;
  border-width: 0px;
  border-image: none;
  border-color: white;
  padding: 0px 8px;
  margin: 0;
`;

SearchBar.SearchLabel = SearchLabel;
SearchBar.InputWrapper = InputWrapper;
SearchBar.SearchInput = SearchInput;
SearchBar.Buttons = Buttons;
SearchBar.SearchIcon = SearchIcon;
SearchBar.ClearIcon = ClearIcon;

export default SearchBar;
