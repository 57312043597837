export const STRINGS = {
  API: {
    ROOT_URL: 'https://ecapitest.webgp.com'
  },
  EXIT_URL_SUFFIX: '.econsulttest.health',
  GA_ID: "UA-30784101-11",
  BANNER_ARCHIVE: {
    DEFAULT_HEALTH_ALERT_URL: "https://s3.eu-west-2.amazonaws.com/banner.webgp.com/healthAlert.html",
  },
};
