import _ from "lodash";
import React, { useState, useEffect } from "react";
import { PracticeInformation } from "components/practiceInformation";
import { H1 } from "styles/elements/header";
import Results from "styles/blocks/results";
import { animated, useSpring } from "react-spring";
import InfiniteScroll from "react-infinite-scroller";
import ReactGA from "react-ga";

const AnimatedResults = animated(Results);

export function SearchResults({ numPractices, practiceArray, hasSearched, searchText }) {

  const sizeOfPracticesArray = _.size(practiceArray);
  const [practiceItems, setPracticeItems] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(8);
  const [resultsToShow, setResultsToShow] = useState(practiceItems.slice(0, itemsToShow));

  useEffect(() => {
    setPracticeItems(_.compact(_.map((practiceArray), (practice, i) => (
      <PracticeInformation
        key={`practiceResult-${i}`}
        name={practice.name}
        address={practice.address}
        id={practice.id}
        state={practice.state}
        alternativeName={practice.alternativeName}
        practiceStructure={practice.practiceStructure}
        parentPracticeName={practice.parentPracticeName}
        branchPractices={practice.branchPractices}
      />
    ))));
  }, [practiceArray]);

  useEffect(() => {
    setResultsToShow(practiceItems.slice(0, itemsToShow));
  }, [practiceItems, itemsToShow]);

  useEffect(() => {
    if (hasSearched) {
      ReactGA.event({
        category: "Search",
        action: "Search results returned",
        label: sizeOfPracticesArray.toString()
      });
      if (sizeOfPracticesArray === 0) {
        ReactGA.event({
          category: "Search",
          action: "Search text returned 0 results",
          label: searchText
        });
      }
    }
  }, [sizeOfPracticesArray, hasSearched, searchText]);
  const loadItems = () => {
    setItemsToShow(itemsToShow + 1);
  };
  const animProps = useSpring(hasSearched
    ? {
      config: { friction: 80 },
      from: {
        display: "none",
        opacity: 0,
        marginTop: 250
      },
      to: {
        display: "block",
        opacity: 1,
        marginTop: 0
      }
    } : {
      config: { friction: 80 },
      to: {
        opacity: 0,
        marginTop: 250
      },
      from: {
        opacity: 0,
        marginTop: 0
      }
    });

  if (numPractices > 0) {
    return (
      <AnimatedResults style={animProps}>
        <Results.Header>We found {numPractices > 8 ? 8 : numPractices} matching practice{numPractices === 1 ? "" : "s"}:</Results.Header>
        <Results.ResultContainer>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => loadItems()}
            hasMore={itemsToShow < numPractices}
          >
            {resultsToShow}
          </InfiniteScroll>
        </Results.ResultContainer>
      </AnimatedResults>
    );
  }
  return (
    <AnimatedResults style={animProps}>
      <Results.ResultContainer>
        <H1>It doesn't look like this practice offers eConsult</H1>
        <Results.Text>Try searching again or visit your surgery's website for more details.</Results.Text>
      </Results.ResultContainer>
    </AnimatedResults>
  );
}
