import styled from "styled-components";
import media from "styles/media";


const HealthAlertContent = styled.div`
  h1 {
    font-size: 24px;
    font-weight: bold;
    color: black;
  }
  
  p {
    font-family: 'Open Sans',Helvetica, Arial, serif;
    font-size: 17px;
  }
   
  .notice-banner-alert-inline {
    background: #ffcb00;
    position: relative;
    z-index: 10;
  }
  
  .wrapper {
    line-height: 1.5;
    margin: 0 auto;
    color: black;
    width: 100%;
    display: block;
    padding: 20px;
    box-sizing: border-box;
    ${media.extraExtraSmallAndAbove`
      width: 80%;
    `}
    ${media.smallAndAbove`
      width: 60%;
      padding: 20px;
      max-width: 700px;
    `}
  }
`;

export default HealthAlertContent;
