import styled from "styled-components";
import { Div } from "styles/elements/div";
import media from "styles/media";

const Title = styled(Div)`
  ${media.smallAndAbove`
  max-width: 600px;
  `}
`;

const DropletLogoImage = styled.img`
  width: 120px;
  height: 74px;
  ${media.extraExtraSmallAndAbove`
    width: 155px;
    height: 96px;
  `}
`;

const HomeHeader = styled.header`
  ${media.smallAndAbove`
    padding-bottom: 45px;
  `}
`;

HomeHeader.DropletLogo = DropletLogoImage;
HomeHeader.Title = Title;

export default HomeHeader;
