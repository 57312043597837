import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { StoreContext } from 'redux-react-hook';
import App from './app';
import * as serviceWorker from './serviceWorker';
import createStore from './redux/store';
import { BrowserRouter as Router } from "react-router-dom";
const store = createStore();

ReactDOM.render(
  <StoreContext.Provider value={store}>
    <Router>
      <App/>
    </Router>
  </StoreContext.Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
