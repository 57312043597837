import styled from 'styled-components';
import media from 'styles/media';
import { Link } from 'react-router-dom';
const LegalFooter = styled.footer`
    background-color: #01669B;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 20;
`;

const LinkList = styled.ul`
    list-style-type: none;
    box-sizing: border-box;
    line-height: 35px;
    font-size: 12px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    ${media.extraExtraSmallAndAbove`
      font-size: 14px;
      margin: 0 auto;
      max-width: 700px;
      width: 60%;
   `}
   ${media.smallAndBelow`
        justify-content: space-between;
        padding: 0 10px;
        width: auto;
   `}
`;

const LinkTo = styled(Link)`
  text-decoration: none;
  color: white;
`;

const ListItem = styled.li`
    display: flex;
    padding-left: 20px;
    ${media.smallAndBelow`
        padding-left: 0;
    `}
`;

LegalFooter.ListItem = ListItem;
LegalFooter.LinkList = LinkList;
LegalFooter.LinkTo = LinkTo;

export default LegalFooter;
