import React from 'react';
import HealthAlertContent from 'styles/blocks/healthAlertContent';
import { healthAlertHtml } from 'static/bannerArchive/healthAlert';

export function HealthAlert() {
  function createMarkup(text) {
    return { __html: text };
  }
  return (
    <HealthAlertContent dangerouslySetInnerHTML={createMarkup(healthAlertHtml)} />
  );
}
