import React from "react";
import LegalContent from "styles/blocks/legalContent";

export function PrivacyPolicy() {
  return (
    <LegalContent>

      <LegalContent.Header1>GP Online Consultation Service Privacy Notices</LegalContent.Header1>

      <LegalContent.Text><LegalContent.Header2>1. Introduction</LegalContent.Header2></LegalContent.Text>

      <LegalContent.Text>
          By law, all organisations using personal information or data must provide
          a clear description of how it is used, providing related information to ensure
          processing is carried out lawfully and fairly. Your GP's main privacy notice
          is available on their website, or by contacting their reception.
      </LegalContent.Text>

      <LegalContent.Text>
          Additional information provided below describes only the use of information when
          you use (for yourself, or on behalf of someone else) your GP's online consultation service.
          Where there are differences depending on practice location (England, Wales, Scotland or Northern Ireland),
          these are specifically noted below.
      </LegalContent.Text>

      <LegalContent.Text>
        <strong>
            Please read your GP’s main privacy notice -
            if you wish to use their online consultation service here,
            please also read the below supplementary information:
        </strong>
      </LegalContent.Text>

      <LegalContent.Text>
        <LegalContent.Header3>
          1a. Online consultation service for GP practices in England
        </LegalContent.Header3>
      </LegalContent.Text>

      <LegalContent.Text>
          Your (or the person you’re filling the form in for’s) GP Practice has engaged
          a specialised online consultation supplier - approved to NHS England technical standards -
          which has gone through stringent scrutiny, achieving all necessary requirements to
          provide online consultations. NHS England, on your GP’s behalf, contracts with the
          supplier and acts as a joint system controller with your GP. However, NHS England
          will not receive any personal information, so your GP remains responsible for this data,
          ensuring that any provided data to use this service is for online consultation purposes only.
      </LegalContent.Text>

      <LegalContent.Text>
          The UK GDPR and The Data Protection Act 2018 (the data protection laws) protect individuals
          with regard to the processing of personal data.The organisation providing this service is
          eConsult Health Ltd. (eConsult), who will act as a personal data processor under the data
          protection laws.
      </LegalContent.Text>

      <LegalContent.Text>
          When accessing the service, NHS England is a controller jointly with GPs and eConsult is a processor for GPs,
          managed through the joint controller relationship that NHS England established with GPs and continues to maintain.
      </LegalContent.Text>

      <LegalContent.Text>
          Please note that if you access our service using your NHS login details the identity verification services are managed by NHS England.
          NHS England is the controller for any personal information you provided to NHS England to get an NHS login account and verify your identity,
          and uses that personal information solely for that single purpose. For this personal information,
          our role is a “processor” only and we must act under the instructions provided by NHS England (as the “controller”)
          when verifying your identity. To see NHS England’s Privacy Notice and Terms and Conditions, please <a href="https://access.login.nhs.uk/terms-and-conditions" target="_blank">click here</a>.
          This restriction does not apply to the personal information you provide to us separately.
      </LegalContent.Text>

      <LegalContent.Text>
        <LegalContent.Header3>
            1b. Online consultation services for GP practices in Wales,
            Scotland or Northern Ireland
        </LegalContent.Header3>
      </LegalContent.Text>

      <LegalContent.Text>
          Your (or the person you’re filling the form in for’s) GP Practice has engaged
          a specialised online consultation supplier, which has gone through stringent scrutiny,
          achieving all necessary requirements to provide online consultations.
          The GP practice has contracted with the supplier, so acts as a system controller.
          They remain responsible for personal data, and will ensure that any provided data to
          this service is used for online consultation purposes only.
      </LegalContent.Text>

      <LegalContent.Text>
          The organisation providing this service is eConsult Health Ltd (eConsult),
          who act as a personal data processor under the data protection laws.
      </LegalContent.Text>

      <LegalContent.Text>
        <LegalContent.Header2>
          2. The lawful basis for your GP’s online consultation service
        </LegalContent.Header2>
      </LegalContent.Text>

      <LegalContent.Text>
          The following legal bases set out in the General Data Protection Regulation (GDPR)
          and UK Data Protection Act 2018 allow your (or the person you’re filling forms in for’s)
          GP to use personal information when you use their provided online consultation service
          (for yourself, or on someone else’s behalf):
      </LegalContent.Text>

      <LegalContent.PrivacyPolicyOrderedList>
        <LegalContent.ListItem>When using your (or who you’re filling the form in for’s)
                personal information (Personal Data):
          <LegalContent.UnorderedList>
            <LegalContent.ListItem>
                Article 6 (1) (e) of the GDPR, which permits your GP practice
                to process necessary personal information to provide a service in the public interest
            </LegalContent.ListItem>
          </LegalContent.UnorderedList>
        </LegalContent.ListItem>

        <LegalContent.ListItem>When using your (or who you’re filling the form in for’s)
                medical data or other personal data of a sensitive nature

          <LegalContent.UnorderedList>
            <LegalContent.ListItem>
                Article 9(2) (h) of the GDPR, which permits your GP practice
                to process necessary health information for health treatment provision
            </LegalContent.ListItem>
          </LegalContent.UnorderedList>
        </LegalContent.ListItem>
      </LegalContent.PrivacyPolicyOrderedList>

      <LegalContent.Text><LegalContent.Header2>3. Data processing purposes</LegalContent.Header2></LegalContent.Text>

      <LegalContent.Text>
          Online consultations allow GP patients to contact the practice,
          without having to wait on the phone or come into the practice - especially
          if a patient isn’t sure whether they need a face-to-face consultation.
          Online consultations enable patients to use secure online systems to ask
          questions and report symptoms. The practice can respond and signpost patients
          to the right person (such as a doctor), or to appropriate service or support.
      </LegalContent.Text>

      <LegalContent.Text><LegalContent.Header2>4. Personal information used</LegalContent.Header2></LegalContent.Text>

      <LegalContent.Text>
          This service is online, so the GP practice needs to ensure it’s confidential and high-quality.
          To do this, they need to properly identify you (or the person you’re filling in forms for),
          accurately noting both initial requests and their responses. If they are prevented from having
          this essential information, they will be unable to provide a secure, confidential service.
      </LegalContent.Text>

      <LegalContent.Text>
          eConsult doesn’t collect any personal data which is not needed to deliver the
          service to you (or to the person you are filling in the form for).
      </LegalContent.Text>

      <LegalContent.Header3>4a. GP practices in England:</LegalContent.Header3>

      <LegalContent.Text>GP practices use the following information to identify and deal with each request:</LegalContent.Text>

      <LegalContent.Table>
        <LegalContent.TableRow>
          <LegalContent.TableCell>
            <LegalContent.Text><strong>Identity and contact information</strong></LegalContent.Text>
          </LegalContent.TableCell>
          <LegalContent.TableCell>
            <LegalContent.Text>Including:</LegalContent.Text>
            <LegalContent.UnorderedList>
              <LegalContent.ListItem>name</LegalContent.ListItem>
              <LegalContent.ListItem>gender</LegalContent.ListItem>
              <LegalContent.ListItem>date of birth</LegalContent.ListItem>
              <LegalContent.ListItem>NHS number</LegalContent.ListItem>
              <LegalContent.ListItem>email address</LegalContent.ListItem>
              <LegalContent.ListItem>telephone number</LegalContent.ListItem>
              <LegalContent.ListItem>postal address</LegalContent.ListItem>
            </LegalContent.UnorderedList>

            <LegalContent.Text>
                If you (or the person you’re filling information in for) have NHS login details,
                you will already be verified, so can (if you wish) use these details on your online consultation,
                to save time and avoid manually entering details, or re-identifying yourself.
            </LegalContent.Text>
          </LegalContent.TableCell>
        </LegalContent.TableRow>
        <LegalContent.TableRow>
          <LegalContent.TableCell>
            <LegalContent.Text><strong>Special personal information categories</strong></LegalContent.Text>
          </LegalContent.TableCell>
          <LegalContent.TableCell>
            <LegalContent.Text>Health information, like:</LegalContent.Text>
            <LegalContent.UnorderedList>
              <LegalContent.ListItem>symptoms</LegalContent.ListItem>
              <LegalContent.ListItem>conditions</LegalContent.ListItem>
              <LegalContent.ListItem>medication</LegalContent.ListItem>
              <LegalContent.ListItem>
                  other details already held in GP records, and/or which you provide during an online consultation.
              </LegalContent.ListItem>
            </LegalContent.UnorderedList>
          </LegalContent.TableCell>
        </LegalContent.TableRow>
      </LegalContent.Table>

      <LegalContent.Header3>4b. GP practices in Wales, Scotland or Northern Ireland:</LegalContent.Header3>

      <LegalContent.Text>Your GP uses the following information to identify and deal with each request:</LegalContent.Text>

      <LegalContent.Table>
        <LegalContent.TableRow>
          <LegalContent.TableCell>
            <LegalContent.Text><strong>Identity and contact information</strong></LegalContent.Text>
          </LegalContent.TableCell>
          <LegalContent.TableCell>
            <LegalContent.Text>Including:</LegalContent.Text>
            <LegalContent.UnorderedList>
              <LegalContent.ListItem>name</LegalContent.ListItem>
              <LegalContent.ListItem>gender</LegalContent.ListItem>
              <LegalContent.ListItem>date of birth</LegalContent.ListItem>
              <LegalContent.ListItem>NHS number</LegalContent.ListItem>
              <LegalContent.ListItem>email address</LegalContent.ListItem>
              <LegalContent.ListItem>telephone number</LegalContent.ListItem>
              <LegalContent.ListItem>postal address</LegalContent.ListItem>
            </LegalContent.UnorderedList>
          </LegalContent.TableCell>
        </LegalContent.TableRow>
        <LegalContent.TableRow>
          <LegalContent.TableCell>
            <LegalContent.Text><strong>Special personal information categories</strong></LegalContent.Text>
          </LegalContent.TableCell>
          <LegalContent.TableCell>
            <LegalContent.Text>Health information, like:</LegalContent.Text>
            <LegalContent.UnorderedList>
              <LegalContent.ListItem>symptoms</LegalContent.ListItem>
              <LegalContent.ListItem>conditions</LegalContent.ListItem>
              <LegalContent.ListItem>medication</LegalContent.ListItem>
              <LegalContent.ListItem>
                  other details held in GP records, and/or which you provide during the online consultation.
              </LegalContent.ListItem>
            </LegalContent.UnorderedList>
          </LegalContent.TableCell>
        </LegalContent.TableRow>
      </LegalContent.Table>

      <LegalContent.Text>Please refer to each GP practice’s main privacy notice for any further information.</LegalContent.Text>

      <LegalContent.Text><LegalContent.Header2>5. Personal information sharing</LegalContent.Header2></LegalContent.Text>

      <LegalContent.Header3>5a. Your GP controls your information</LegalContent.Header3>

      <LegalContent.Text>
          As mentioned earlier, your (or the person you’re filling forms in for’s)
          GP has engaged the specialised organisation eConsult to provide an online consultation
          service on their behalf - personal data will be shared with them so they can provide
          this service. If you are advised to seek urgent care, your information will not be
          shared with other health and care providers.
      </LegalContent.Text>

      <LegalContent.Text>
        <strong>For GP practices in England</strong>, NHS England confirms they will only use
        personal information to provide health services.
      </LegalContent.Text>

      <LegalContent.Text>
        <strong>For GP practices in Wales, Scotland and Northern Ireland</strong>, please refer to
        individual GP practice main privacy notice(s), relating to personal information use.
      </LegalContent.Text>

      <LegalContent.Header3>5b. The NHS app (England only)</LegalContent.Header3>

      <LegalContent.Text>
          This online consultation service is made available to patients using the NHS app,
          which can be downloaded from the App Store or Google Play. Provided by NHS England,
          the NHS app offers health services like viewing your medical record. If you are logged into the NHS app,
          you will also have access to your GP’s online consultation service, and any requests made will
          be securely sent from the NHS app to their practice system (via NHS England.)
      </LegalContent.Text>

      <LegalContent.Text>
          Whenever a GP shares personal information, they will always comply with the law.
      </LegalContent.Text>

      <LegalContent.Text><LegalContent.Header2>6. Information processing and storage</LegalContent.Header2></LegalContent.Text>

      <LegalContent.Text>Your personal data is processed and stored within the United Kingdom.</LegalContent.Text>

      <LegalContent.Text><LegalContent.Header3>How long personal information is kept</LegalContent.Header3></LegalContent.Text>

      <LegalContent.Text>
          Your (or the person you’re filling forms in for’s) GP practice sets personal information
          retention periods and instructs eConsult, the engaged contractor providing online consultations on their behalf,
          to comply with these. When information is copied to internal GP systems, sensitive information will be
          deleted by eConsult. eConsult retain contact details (name, email) for up to 5 weeks, to allow communication
          relating to consultations, after which this is automatically deleted. If you are advised to seek urgent
          care elsewhere, personal information will not be transferred to us, or retained after you have read
          this advice.
      </LegalContent.Text>

    </LegalContent>
  );
}
