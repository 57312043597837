import { SearchResults } from "components/searchResults";
import React, { useCallback, useState, useLayoutEffect, useRef } from "react";
import { Div, HiddenDiv } from "styles/elements/div";
import Home from "styles/blocks/home";
import { HealthAlert } from "components/healthAlert";
import { SearchHeader } from "components/searchHeader";
import { SearchForm } from "components/searchForm";
import { HowItWorks } from "components/howItWorks";
import { useDispatch, useMappedState } from "redux-react-hook";
import Footer from "styles/blocks/footer";
import EConsultLogo from "assets/branding/logo_econsult_REVERSED_RGB.svg";
import { useSpring, useTransition, animated } from "react-spring";
import { showHideFooterText } from "redux/actions/search";
import LegalFooter from "styles/blocks/legalFooter";
import Logo from "styles/blocks/logo";
import _ from 'lodash';

const AnimatedDiv = animated(Div);

export function Search() {
  const mapState = useCallback(
    state => ({
      practicesToDisplay: state.searchReducer.practicesToDisplay,
      hasSearched: state.searchReducer.hasSearched,
      showFooterText: state.searchReducer.showFooterText,
      searchText: state.searchReducer.searchText
    }), []
  );

  const dispatch = useDispatch();

  const { showFooterText, practicesToDisplay, hasSearched, searchText } = useMappedState(mapState);

  const toggleFooterText = () => {
    return dispatch(showHideFooterText(!showFooterText));
  };

  const logoAnimProps = useSpring({
    config: { duration: 900 },
    opacity: 1,
    from: { opacity: 0 },
    delay: 200
  });

  const [headerHeight, setHeaderHeight] = useState(window.innerWidth > 450 ? 300 : 200);

  const scrollableContainerRef = useRef(null);

  useLayoutEffect(() => {
    // This listens for the media query to change (eg. when device orientation changes) and updates headerHeight.
    const detectDeviceOrientation = (width) => {
      if (width.matches) {
        setHeaderHeight(300);
      } else {
        setHeaderHeight(200);
      }
    };
    const screenWidth = window.matchMedia('(min-width: 480px)');
    screenWidth.addListener(detectDeviceOrientation);

    return () => screenWidth.removeListener(detectDeviceOrientation);
  }, [headerHeight]);

  const transitions = useTransition(hasSearched, null, {
    config: { friction: 65 },
    from: {
      maxHeight: headerHeight,
      opacity: 1,
      marginTop: 0,
      marginBottom: 0
    },
    update: { maxHeight: headerHeight },
    leave: {
      maxHeight: 0,
      opacity: 0,
      marginTop: -headerHeight,
      marginBottom: headerHeight
    }
  });
  const headerAnimProps = useSpring({
    config: { duration: 900 },
    opacity: 1,
    from: { opacity: 0 },
    delay: 1100
  });

  const firstLoadFooterProps = useSpring({
    config: { duration: 900 },
    opacity: 1,
    from: { opacity: 0 },
    delay: 2900
  });
  const containerProps = useSpring({
    config: { friction: 60 },
    bottom: showFooterText ? "45vh" : "0vh",
    from: {
      bottom: showFooterText ? "0vh" : "0vh",
    }
  });

  return (
    <Home ref={scrollableContainerRef}>
      <nav name="Skip to content nav">
        <HiddenDiv>
          <a href="#mainContent">Skip to content</a>
        </HiddenDiv>
      </nav>
      <AnimatedDiv style={logoAnimProps}>
        <HealthAlert/>
      </AnimatedDiv>
      <Home.ResponsiveContainer id="mainContent">
        <AnimatedDiv style={logoAnimProps}>
          <Logo>
            <Logo.LogoLink href="/">
              <Logo.Image alt="eConsult logo" src={EConsultLogo}/>
            </Logo.LogoLink>
          </Logo>
        </AnimatedDiv>
        {transitions.map(({ item, key, props }) => {
          return item
            || (
              <animated.div key={key} style={props}>
                <Home.Header style={headerAnimProps}>
                  <SearchHeader />
                </Home.Header>
              </animated.div>
            );
        })}

        <SearchForm scrollableContainerRef={scrollableContainerRef} />
        <SearchResults numPractices={_.size(practicesToDisplay)} practiceArray={practicesToDisplay} hasSearched={hasSearched} searchText={searchText}/>
        <Home.FooterWrapper style={firstLoadFooterProps}>
          <Footer style={containerProps} onClick={() => toggleFooterText()}>
            How it works
          </Footer>
        </Home.FooterWrapper>
      </Home.ResponsiveContainer>
      <LegalFooter>
        <LegalFooter.LinkList>
          <LegalFooter.ListItem><LegalFooter.LinkTo to="/legal/accessibility">Accessibility</LegalFooter.LinkTo></LegalFooter.ListItem>
          <LegalFooter.ListItem><LegalFooter.LinkTo to="/legal/disclaimer">Disclaimer</LegalFooter.LinkTo></LegalFooter.ListItem>
          <LegalFooter.ListItem><LegalFooter.LinkTo to="/legal/privacy">Privacy Policy</LegalFooter.LinkTo></LegalFooter.ListItem>
          <LegalFooter.ListItem><LegalFooter.LinkTo to="/legal/terms">Terms of Use</LegalFooter.LinkTo></LegalFooter.ListItem>
        </LegalFooter.LinkList>
      </LegalFooter>
      <HowItWorks/>
    </Home>
  );
}
