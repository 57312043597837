import _ from 'lodash';
import { css } from 'styled-components';
import theme from 'styles/theme';

const sizes = {
  extraLarge: { min: theme.screenXl },
  large: {
    max: theme.screenLgMax,
    min: theme.screenLg
  },
  medium: {
    max: theme.screenMdMax,
    min: theme.screenMd
  },
  small: {
    max: theme.screenSmMax,
    min: theme.screenSm
  },
  extraSmall: {
    max: theme.screenXsMax,
    min: theme.screenXs
  },
  extraExtraSmall: {
    max: theme.screenXXsMax,
    min: theme.screenXXs
  },
  mediumAndAbove: { min: theme.screenMd },
  mediumAndBelow: { max: theme.screenMdMax },
  smallAndBelow: { max: theme.screenSmMax },
  smallAndAbove: { min: theme.screenSm },
  smallAndExtraSmall: {
    min: theme.screenXs,
    max: theme.screenSmMax
  },
  smallAndMedium: {
    min: theme.screenSm,
    max: theme.screenMdMax
  },
  mediumAndLarge: {
    min: theme.screenMd,
    max: theme.screenLgMax
  },
  largeAndAbove: { min: theme.screenLg },
  belowExtraExtraSmall: { max: theme.screenXXs - 1 },
  extraExtraSmallAndAbove: { min: theme.screenXXs },
  smallAndLandscape: { orientation: "landscape" }
};

const mediaForSizes = settings => {
  if (settings.min && settings.max) {
    return `@media (min-width: ${settings.min}px) and (max-width: ${settings.max}px)`;
  }
  if (settings.min) {
    return `@media (min-width: ${settings.min}px)`;
  }
  if (settings.orientation) {
    return `@media (max-height: 500px) and (orientation: landscape)`;
  }
  return `@media (max-width: ${settings.max}px)`;
};

const sizeQueries = _.mapValues(sizes, settings => {
  return (...args) => css`
    ${mediaForSizes(settings)} {
      ${css(...args)};
    }
  `;
});

export default {
  ...sizeQueries,
};
