import styled from "styled-components";
import { Div } from "styles/elements/div";
import media from "styles/media";
import { animated } from 'react-spring';

const HeaderDiv = styled(Div)`
  marginTop: 0;
`;

const FooterWrapper = styled(Div)`
  bottom: 0;
  position: fixed;
  z-index: 22;
`;

const Home = styled(Div)`
  background-color: #009cdc;
  top: 0px;
  height: 100%;
  overflow-y: scroll;
`;

const HomeLink = styled.a``;

const ResponsiveContainer = styled.main`
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 20px 20px 50px;
  box-sizing: border-box;
  ${media.extraExtraSmallAndAbove`
    width: 80%;
  `}
  ${media.smallAndAbove`
    width: 60%;
    max-width: 700px;
  `}
`;

Home.ResponsiveContainer = ResponsiveContainer;
Home.FooterWrapper = animated(FooterWrapper);
Home.Header = animated(HeaderDiv);
Home.HomeLink = HomeLink;

export default Home;
