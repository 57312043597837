import React, { useCallback, useRef, useEffect } from "react";
import { getPracticesToDisplay, showHideFooterText, updateSearchTextValue } from "redux/actions/search";
import { useDispatch, useMappedState } from "redux-react-hook";
import SearchBar from "styles/blocks/searchBar";
import { Div, HiddenDiv } from "styles/elements/div";
import { useSpring, animated } from "react-spring";

const AnimatedDiv = animated(Div);

export function SearchForm({ scrollableContainerRef }) {
  const mapState = useCallback(
    state => ({
      searchText: state.searchReducer.searchText,
      searchBarText: state.searchReducer.searchBarText,
      hasSearched: state.searchReducer.hasSearched
    }), []
  );
  const dispatch = useDispatch();

  const {
    searchText, searchBarText, hasSearched
  } = useMappedState(mapState);

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      scrollableContainerRef.current.scrollTop = 0;
    }
  }, [inputRef, scrollableContainerRef]);

  const updateSearchText = (updatedText) => {
    return dispatch(updateSearchTextValue(updatedText));
  };
  const hideFooterText = (currentRef) => {
    currentRef.current.scrollIntoView();
    return dispatch(showHideFooterText(false));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (hasSearched && searchBarText === searchText) {
      updateSearchText("");
    } else if (searchBarText !== "") {
      dispatch(getPracticesToDisplay(searchBarText, dispatch));
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchBarText !== "") {
      dispatch(getPracticesToDisplay(searchBarText, dispatch));
    }
  };
  const searchAnimProps = useSpring({
    config: { duration: 900 },
    opacity: 1,
    from: { opacity: 0 },
    delay: 2000
  });

  return (
    <AnimatedDiv style={searchAnimProps}>
      <SearchBar>
        <SearchBar.SearchLabel htmlFor="searchInput">Find your practice by name (for example: <strong>Docklands Medical
          Centre</strong>)
        </SearchBar.SearchLabel>
        <SearchBar.InputWrapper>
          <SearchBar.SearchInput
            type="text"
            name="searchInput"
            id="searchInput"
            value={searchBarText}
            onFocus={() => hideFooterText(inputRef)}
            onKeyPress={event => handleKeyPress(event)}
            onChange={event => updateSearchText(event.target.value)}
            ref={inputRef}
          />
          <SearchBar.Buttons type="button" onClick={(e) => handleSubmit(e)}>
            {hasSearched && searchBarText === searchText ? <SearchBar.ClearIcon/> : <SearchBar.SearchIcon/>}
            <HiddenDiv>
              {hasSearched && searchBarText === searchText ? "Clear your search" : "Search for your practice"}
            </HiddenDiv>
          </SearchBar.Buttons>
        </SearchBar.InputWrapper>
      </SearchBar>
    </AnimatedDiv>
  );
}
