import styled from 'styled-components';
import media from "styles/media";
import { Div } from 'styles/elements/div';

const Logo = styled(Div)`
  height: 50px;  
  margin-bottom: 40px;
  ${media.smallAndAbove`
    margin-top: 20px;
  `}
  position:relative;
  z-index: 1;
`;
const LogoLink = styled.a`
  text-decoration: none;
  border-style: none;
  border: none;
  border-width: 0px;
`;
const EconsultLogoImage = styled.img`
  width: 160px;
  height: 47px;
  border: none;
  ${media.belowExtraExtraSmall`
    width: 100px;
    height: 30px;
  `}
`;

Logo.Image = EconsultLogoImage;
Logo.LogoLink = LogoLink;
export default Logo;
