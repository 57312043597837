/**
 *
 * Using BEM design pattern detailed here: https://tech.decisiv.com/structuring-our-styled-components-part-i-2bf21fa64b28
 *
 * BEM definition can be found here: http://getbem.com/
 *
 */

const screenXXs = 480;
const screenXs = 550;
const screenSm = 768;
const screenMd = 992;
const screenLg = 1200;
const screenXl = 1500;

const xsGridMargin = 10;
const smGridMargin = 20;
const mdGridMargin = 25;
const lgGridMargin = 50;
const screenLgWidth = screenLg - 2 * lgGridMargin;

const bgPrimaryDark = '#303030';
const bgPrimaryLight = '#F3F2F3';
const bgPrimaryLightShadeOne = '#E7E7E7';

const brandPrimary = '#C93C4C';
const brandPrimaryShadeOne = '#ffd9dd';
const brandPrimaryShadeTwo = '#ffbcc3';
const brandPrimaryShadeThree = '#ff6978';
const brandPrimaryDark = '#3A3C49';

const linkColor = '#4890ad';

const fontColorPrimary = 'black';
const fontPrimary = `
  font-family: 'Open Sans', arial, sans-serif;
  color: ${fontColorPrimary};
`;
const fontPrimaryBold = `
  font-weight: bold;
  ${fontPrimary}
`;
const fontFamilySecondary = 'Quicksand, arial, sans-serif';

const borderLight = `${bgPrimaryLightShadeOne} solid 2px`;

export default {
  screenXXs: screenXXs,
  screenXs: screenXs,
  screenSm: screenSm,
  screenMd: screenMd,
  screenLg: screenLg,
  screenXl: screenXl,
  screenXXsMax: screenXs - 1,
  screenXsMax: screenSm - 1,
  screenSmMax: screenMd - 1,
  screenMdMax: screenLg - 1,
  screenLgMax: screenXl - 1,
  screenLgWidth: screenLgWidth,
  lgGridMargin: lgGridMargin,
  mdGridMargin: mdGridMargin,
  smGridMargin: smGridMargin,
  xsGridMargin: xsGridMargin,

  bgPrimaryDark: bgPrimaryDark,
  bgPrimaryLight: bgPrimaryLight,
  bgPrimaryLightShadeOne: bgPrimaryLightShadeOne,

  brandPrimary: brandPrimary,
  brandPrimaryShadeOne: brandPrimaryShadeOne,
  brandPrimaryShadeTwo: brandPrimaryShadeTwo,
  brandPrimaryShadeThree: brandPrimaryShadeThree,
  brandPrimaryDark: brandPrimaryDark,

  linkColor: linkColor,

  fontColorPrimary: fontColorPrimary,
  fontPrimary: fontPrimary,
  fontPrimaryBold: fontPrimaryBold,
  fontFamilySecondary: fontFamilySecondary,

  borderLight: borderLight,
};
