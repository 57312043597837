import styled from 'styled-components';
import media from 'styles/media';
import Button from 'styles/elements/button';
import { animated } from 'react-spring';

const Footer = animated(styled(Button)`
  font-family: 'Quicksand', Helvetica, Arial, serif;
  border: 0;
  font-weight: 700;
  font-style: normal;
  color: white;
  background-color:rgb(196,70,68);
  position: fixed;
  font-size: 16px;
  bottom: 0;
  margin: 0 0 35px 0;
  left: 0px;
  padding: 10px 20px;
  border-radius: 0 15px 0 0;
 ${media.extraExtraSmallAndAbove`
    left: auto;
    font-size: 19px;
    line-height: 1.5;
    border-radius: 15px 15px 0 0;
 `}
`);

export default Footer;
