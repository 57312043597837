import React from "react";
import LegalContent from "styles/blocks/legalContent";

export function Accessibility() {
  return (
    <LegalContent tag="main1">
      <LegalContent.Header1>eConsult Patient Portal Website accessibility statement</LegalContent.Header1>
      <LegalContent.Text>
        The eConsult Patient Portal Website is run by <LegalContent.Link href="https://econsult.net/" target="_blank">eConsult Health Ltd.</LegalContent.Link>
        The eConsult Patient Portal Website is accessed from a browser on a desktop, laptop, tablet or mobile device.
      </LegalContent.Text>
      <LegalContent.Header2>Technical information about accessibility</LegalContent.Header2>
      <LegalContent.Text>
        As a provider to the NHS, eConsult Health Ltd is committed to making the eConsult Patient Portal Website accessible,
        in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.
      </LegalContent.Text>
      <LegalContent.Header2>Compliance status</LegalContent.Header2>
      <LegalContent.Text>
        The Website is partially compliant with the <LegalContent.Link href="https://www.w3.org/TR/WCAG21/" target="_blank">Web Content Accessibility Guidelines version 2.1 AA standard.</LegalContent.Link>
      </LegalContent.Text>
      <LegalContent.Header2>How accessible is this Website?</LegalContent.Header2>
      <LegalContent.Text>
        We want as many people as possible to be able to use the eConsult Patient Portal Website.
        This means that on the Website you should be able to:
      </LegalContent.Text>
      <LegalContent.UnorderedList>
        <LegalContent.ListItem>change colours, contrast levels and fonts.</LegalContent.ListItem>
        <LegalContent.ListItem>zoom in up to 200% with the text staying visible on the screen.</LegalContent.ListItem>
        <LegalContent.ListItem>navigate using just a keyboard.</LegalContent.ListItem>
        <LegalContent.ListItem>navigate using speech recognition software.</LegalContent.ListItem>
        <LegalContent.ListItem>read using a screen reader, including the latest versions of JAWS, NVDA and VoiceOver.</LegalContent.ListItem>
      </LegalContent.UnorderedList>
      <LegalContent.Text>Parts of this Website may not be fully accessible. For example:</LegalContent.Text>
      <LegalContent.UnorderedList>
        <LegalContent.ListItem>Some WAI-ARIA landmarks may not have their roles properly applied</LegalContent.ListItem>
      </LegalContent.UnorderedList>
      <LegalContent.Text>While this issue has been recorded, we do not have a timescale for when they will be fixed.</LegalContent.Text>
      <LegalContent.Header2>How we test the Website</LegalContent.Header2>
      <LegalContent.Text>
        Our internal Quality Assurance team tested the Website for compliance with the
        Web Content Accessibility Guidelines v2.1 level A and level AA.
      </LegalContent.Text>
      <LegalContent.Text>
        The tests were carried out using the SiteImprove Accessibility Testing Tool and the axe Accessibility Testing Tool.
        Both tools check for WCAG 2.1 success criteria.
      </LegalContent.Text>
      <LegalContent.Text>
        The Website was also audited for accessibility compliance using the Lighthouse Tool and was scored 97/100.
      </LegalContent.Text>
      <LegalContent.Text>We have not undertaken any testing specifically with speech recognition software.</LegalContent.Text>
      <LegalContent.Header2>Adjustments for specific disabilities</LegalContent.Header2>
      <LegalContent.Text>
        For colour blindness (protanomaly, deuteranomaly, tritanomaly) we use a restricted palette that
        avoids red/green, blue/yellow, blue/green, and violet/red proximity.
      </LegalContent.Text>
      <LegalContent.Text>
        For blindness, we ensure that all images and inputs have suitable metadata that screen-readers can use to indicate where a user is.
        We also use native inputs that have better support for screen-readers.
      </LegalContent.Text>
      <LegalContent.Text>
        Textual information is never embedded within images.
        For the hearing impaired and deaf, we do not use sound as a primary communication modality currently.
        All information is conveyed as text or image.
      </LegalContent.Text>
      <LegalContent.Header2>What we’re doing to improve accessibility</LegalContent.Header2>
      <LegalContent.Text>Our Quality Assurance team includes WCAG 2.1 testing as part of every update to the Website.</LegalContent.Text>
      <LegalContent.Header2>How to share your feedback and contact us about accessibility issues</LegalContent.Header2>
      <LegalContent.Text>
        If you need information about accessing this Website or wish to give us feedback, send an email to <LegalContent.Link href="mailto:support@econsult.health" target="_blank">support@econsult.health</LegalContent.Link>.
      </LegalContent.Text>
      <LegalContent.Header2>Enforcement procedure</LegalContent.Header2>
      <LegalContent.Text>
        The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications)
        (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’).
        If you’re not happy with how we respond to your complaint, contact the Equality Advisory and Support Service (EASS).
      </LegalContent.Text>
      <LegalContent.Header2>Preparation of this accessibility statement</LegalContent.Header2>
      <LegalContent.Text>This statement was last updated on 1st of October 2021.</LegalContent.Text>
    </LegalContent>
  );
}
