import styled from "styled-components";
import { animated } from "react-spring";
import { Div } from "styles/elements/div";
import media from "styles/media";

const ExampleUsages = styled.ul`
  font-size: 15px;
  line-height: 1.5;
  padding-left: 20px;
  ${media.extraExtraSmallAndAbove`
   font-size: 19px;
  `}    
  ${media.smallAndLandscape`
   font-size: 3.8vh
  `}
`;
const Text = styled(Div)`
  font-family: 'Open Sans',Helvetica, Arial, serif;
  font-size: 15px;
  color: white;
  margin: 0 auto;
  padding: 12px; 
  box-sizing: border-box;
  ${media.extraExtraSmallAndAbove`
    width: 80%;
    font-size: 19px;
    padding: 15px;
  `}
  ${media.largeAndAbove`
    width: 60%;
    max-width: 640px;
    padding: 20px 0;
  `}
  ${media.smallAndLandscape`
    font-size: 3.8vh;
    width: 90%;
    padding: 5px;
  `}
`;
const TextContainer = styled(animated(Div))`
  bottom: 0px;
  max-height: 0px;
  line-height: 1.5;
  margin: 0 auto;
  height: 45vh;
  background-color:rgb(196,70,68);
`;

const HowItWorksFooter = styled.article`
  position:fixed;
  overflow: hidden;
  bottom: 35px;
  width: 100%;
  z-index: 22;
`;

HowItWorksFooter.TextContainer = TextContainer;
HowItWorksFooter.Text = Text;
HowItWorksFooter.ExampleUsages = ExampleUsages;

export default HowItWorksFooter;
