import { apiPost } from "common/apiRequests";
import ReactGA from "react-ga";

const apiGetPracticeByTextSearch = (dispatch, text) => {
  const requestBody = JSON.stringify({ search: text });
  return apiPost("/public/search")
    .type("application/json")
    .send(requestBody)
    .then(response => dispatch({
      type: "search/GET_PRACTICES_SUCCESS",
      payload: response.body
    }))
    .catch(error => dispatch({
      type: "search/GET_PRACTICES_ERROR",
      payload: error.message
    }));
};
export const getPracticesToDisplay = (text) => dispatch => {
  ReactGA.event({
    category: "Search",
    action: "User searched for a practice",
    label: text
  });
  dispatch({ type: "search/GET_PRACTICES_REQUEST" });
  apiGetPracticeByTextSearch(dispatch, text);
};

export const updateSearchTextValue = (value) => ({
  type: "search/UPDATE_SEARCH_TEXT",
  payload: value
});

export const showHideFooterText = (value) => ({
  type: "search/SHOW_HIDE_FOOTER_TEXT",
  payload: value
});

export const hideResults = () => ({ type: "search/HIDE_RESULTS" });
