import styled from "styled-components";
import { Div } from "styles/elements/div";
import { H1 } from 'styles/elements/header';

const ResultContainer = styled(Div)`
  margin-bottom: 0px;
  max-
  height: 70vh;
`;

const Results = styled(Div)`
  flex-direction: column;
  opacity: 0;
  display: none;
  padding-bottom: 60px;
`;

const Header = styled(H1)``;
const Text = styled.p`
  font-size: 20px;
`;
Results.ResultContainer = ResultContainer;
Results.Header = Header;
Results.Text = Text;

export default Results;
