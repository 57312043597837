import React, { useCallback } from "react";
import PracticeInfo from "styles/blocks/practiceInfo";
import { STRINGS as CONSTANTS } from "config/index";
import _ from "lodash";
import { useMappedState } from "redux-react-hook";

export function PracticeInformation(
  {
    state,
    name,
    address,
    id,
    alternativeName,
    practiceStructure,
    parentPracticeName,
    branchPractices
  }
) {
  const mapState = useCallback(
    searchState => ({
      searchText: searchState.searchReducer.searchText
    }), []
  );

  const { searchText } = useMappedState(mapState);
  const practiceURL = "https://" + id + CONSTANTS.EXIT_URL_SUFFIX;
  if (state === "LIVE") {
    return (

      <PracticeInfo>
        <PracticeInfo.ResultBox>
          <PracticeInfo.Link href={practiceURL}>
            <PracticeInfo.ResultHeader>
              {name + (practiceStructure === "BRANCH" ? " (part of " + parentPracticeName + ")" : "")}
            </PracticeInfo.ResultHeader>
            <PracticeInfo.Body>
              {practiceStructure !== "PARENT" && <PracticeInfo.Text>{address}</PracticeInfo.Text>}
              {practiceStructure === "STANDALONE" && alternativeName && (
                <PracticeInfo.BranchesContainer>
                  <PracticeInfo.Text><strong>Practice branch names:</strong></PracticeInfo.Text>
                  <PracticeInfo.BranchNames>
                    {_.compact(_.map((alternativeName.split("|")), (branchName, i) => (
                      branchName.toLowerCase().includes(searchText.toLowerCase())
                        ? <PracticeInfo.ListItem key={i}><PracticeInfo.Highlight>{branchName}</PracticeInfo.Highlight></PracticeInfo.ListItem>
                        : <PracticeInfo.ListItem key={i}>{branchName}</PracticeInfo.ListItem>
                    )))}
                  </PracticeInfo.BranchNames>
                </PracticeInfo.BranchesContainer>
              )}
              {practiceStructure === "PARENT" && (
                <PracticeInfo.BranchesContainer>
                  <PracticeInfo.BranchDetails>
                    {_.compact(_.map(branchPractices, (branchPractice, i) => (
                      branchPractice.practiceName.toLowerCase().includes(searchText.toLowerCase())
                        ? (
                          <PracticeInfo.ListItem key={i}>
                            <PracticeInfo.Highlight>
                              <strong>
                                {branchPractice.practiceName}
                              </strong>
                            </PracticeInfo.Highlight>
                            <br/>
                            {branchPractice.practiceAddress}
                          </PracticeInfo.ListItem>
                        )
                        : (
                          <PracticeInfo.ListItem key={i}>
                            <strong>
                              {branchPractice.practiceName}
                            </strong>
                            <br/>
                            {branchPractice.practiceAddress}
                          </PracticeInfo.ListItem>
                        )
                    )))}
                  </PracticeInfo.BranchDetails>
                </PracticeInfo.BranchesContainer>
              )}
            </PracticeInfo.Body>
          </PracticeInfo.Link>
        </PracticeInfo.ResultBox>
      </PracticeInfo>

    );
  }
  return (
    <PracticeInfo.GreyedResultBox>
      <PracticeInfo.ResultHeader>{name}</PracticeInfo.ResultHeader>
      <PracticeInfo.Body>{address}
        <br/>
        This practice is getting ready to launch the eConsult service. Check back soon or contact your practice to find out when eConsult will be
        available.
      </PracticeInfo.Body>
    </PracticeInfo.GreyedResultBox>
  );
}
