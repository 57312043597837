import React from 'react';
import LegalContent from 'styles/blocks/legalContent';

export function Disclaimer() {
  return (
    <LegalContent>
      <LegalContent.Text>The Website is operated by eConsult Health Limited, registered in England and Wales with registered number 7628675
        and whose registered office is at Nightingale House, 46-48 East Street, Epsom, Surrey KT17 1HQ (“we” or “us”)
      </LegalContent.Text>

      <LegalContent.List>
        <LegalContent.ListItem>
          Whilst we endeavour to ensure that the information on this website (“Website”) including, but not limited to,
          the know-how published on the Website from time to time including information, guidance, E-consultation templates,
          self-help guides and videos and details of contact numbers and links for NHS emergency and urgent care services (“Know-how”)
          and/or any services offered on the Website (“Services”) is correct, we do not warrant the accuracy and completeness of
          any of the material on the Website. In addition, you should note in particular:
          <LegalContent.List>
            <LegalContent.ListItem>
              The Know-how is for information purposes only and does not constitute advice or a definitive or complete statement on any subject.
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              We do not undertake any obligation to consider whether the information provided to or by us for the purpose
              of our Know-how is either sufficient or appropriate for any particular actual circumstances.
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              The Know-how includes archived information and resources, which may be incorrect or out of date and we and
              make no commitment to update such material.
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              We are a provider of information and general guidance. We do not accept any responsibility for action taken
              as a result of the Know-how or other information provided by us. You must consult your GP or other medical
              adviser to obtain professional or specialist advice before taking, or refraining from, any action on the
              basis of the content on the Website. The Know-how is general and informational in nature and may not reflect
              all recent medical developments and may not apply to the specific facts and circumstances of individual cases.
              We are not a medical practice and do not represent or advise patients in any matter and are not bound by
              the professional responsibilities and duties of a medical practitioner. Nothing in the Know-how nor any
              receipt or use of such Know-how, shall be construed or relied on as advertising or soliciting to provide
              any medical services, creating any patient-client relationship or providing any medical representation,
              advice or opinion whatsoever on behalf of us or our staff.
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              We give you no warranty or assurance that the Know-how or the Services, or our means of delivering them
              are compatible with your software or computer configuration.
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              We may change part or all of the material on the Website at any time without notice.
            </LegalContent.ListItem>
          </LegalContent.List>
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          The material on the Website (including, but not limited to, the Know-how and any Services) is provided “as is”
          without any representations, warranties, conditions or other terms of any kind. Accordingly, to the fullest extent
          permitted by law, we provide you with the Website on the basis that we exclude all representations, warranties,
          conditions and other terms (including, without limitation, the conditions implied by law of satisfactory quality,
          fitness for purpose and the use of reasonable care and skill) which, but for this Disclaimer, might have effect in relation to:
          <LegalContent.List>
            <LegalContent.ListItem>
              the Website; and/or
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              the Know-how and/or the Services or any treatment, information, advice or action relied on or followed
              by any person using the Know-how.
            </LegalContent.ListItem>
          </LegalContent.List>
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          If your use of material on the Website results in the need for servicing, repair or correction of equipment,
          software or data, you assume all costs thereof. We neither represent nor warrant that the material on the Website
          will be compatible with or operate with the software or hardware on your computer. In the event that you either
          enable cookies or download certain publicly accessible software to ensure that your computer is capable of accessing
          the Website, you do so at your own risk. Additional charges may be payable to third parties for use of the software
          necessary to view the Website. You are responsible for paying these charges.
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          We shall not be liable to you:
          <LegalContent.List>
            <LegalContent.ListItem>
              in the event that any of the Know-how or Services are not available to you due to an event beyond our
              reasonable control, including without limitation events such as fire, flood, storm, strikes or other
              industrial action, failure of telecommunications services, war, riot, or the actions of any government
              or public body; or
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              for any data that you lose whether as a result of accessing the Website or otherwise. It is your
              responsibility to save and backup all data which you hold on the computer from which you are accessing
              the Website.
            </LegalContent.ListItem>
          </LegalContent.List>
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          To the fullest extent permitted by law, we shall not be liable for:
          <LegalContent.List>
            <LegalContent.ListItem>
              any loss or damage arising from the fact that the remote nature of the Know-how means that it cannot
              fully replicate the level of emotional support, care or attention often associated with and provided
              by actual medical practitioners, particularly in person;
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              any loss or damage arising from any inaccuracies or incompleteness of information from you;
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              any information that is inadvertently omitted from the Know-how or for any errors that are inadvertently
              included within the Know-how;
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              what you choose to do after having read the Know-how;
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              any liability resulting from any provision of services by your GP or any other medical practitioner
              or other medical adviser, who shall be solely liable for their own services.
            </LegalContent.ListItem>
          </LegalContent.List>
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          Nothing in this disclaimer excludes our liability:
          <LegalContent.List>
            <LegalContent.ListItem>
              for death or personal injury caused by our negligence; or
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              for fraud or fraudulent misrepresentation and
            </LegalContent.ListItem>
            <LegalContent.ListItem>
              for the avoidance of doubt, in this disclaimer, our liability includes that of any company in our group
              and our and their respective agents, employees, contributors and consultants and sub-contractors, "you"
              includes any other party claiming through you and "loss or damage" includes any losses, damages, costs or
              expenses whatsoever or howsoever arising in connection with the Service, whether under the Agreement or
              other agreement or in consequence of any misrepresentation, misstatement or tortious act or omission, including
              negligence.
            </LegalContent.ListItem>
          </LegalContent.List>
        </LegalContent.ListItem>
        <LegalContent.ListItem>
          Links to third party websites (including, without limitation, third party retailers) on the Website are
          provided solely for your convenience. If you use these links, you leave the Website. We have not reviewed
          all of these third party websites and do not control and are not responsible for these websites or their
          content or availability. Except where explicitly stated, we do not endorse or make any representations about
          them, or any material found there, or any results that may be obtained from using them. If you decide to
          access any of the third party websites linked to the Website, you do so entirely at your own risk.
        </LegalContent.ListItem>
      </LegalContent.List>
    </LegalContent>
  );
}
